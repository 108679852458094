import React, { useState } from 'react';

import Helmet from '~components/helmet';
import Waves from '~components/waves/waves-new';
import Hero from '~components/home/hero';
import Integrations from '~components/home/integrations';

import '~components/style.scss';
import './layout.scss';

const Layout = ({ location }) => {
  const [isHeroVisible, setIsHeroVisible] = useState(false);

  return (
    <div className="layout homepage">
      <Helmet />
      <Waves />
      <Hero isHeroVisible={isHeroVisible} />
      <Integrations />
    </div>
  );
};

export default Layout;
