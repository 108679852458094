import React, { useEffect } from 'react';

import { useSpring, animated } from 'react-spring';
import { smoothScrollTo } from '~lib/utils';

import './hero.scss';
import Section from '~components/section';

const Hero = ({ isHeroVisible }) => {
  const scrollerOpacityProp = useSpring({
    opacity: isHeroVisible ? 1 : 1,
    config: { duration: 150 },
    delay: isHeroVisible ? 800 : 0,
  });

  useEffect(() => {
    if (sessionStorage.getItem('returningVisit')) {
      var d = document.getElementById('___gatsby');
      d.classList.add('notloaded');
    } else {
      sessionStorage.setItem('returningVisit', true);
      var d = document.getElementById('___gatsby');
      d.classList.add('loaded');
    }
  });

  return (
    <Section className="section hero" name="hero" id="hero" fullHeight>
      <div className="loadingAnim"></div>

      <div className="container">
        <div className="title-section-wrapper has-text-centered">
          <h1 className="title is-size-3-mobile">
            <span className="row1">we invest in</span>
            <br />
            <span className="row2">infrastructure software</span>
          </h1>
          <p className="subtitle is-size-5-mobile">
            {'Traditional and Decentralized'}
          </p>
        </div>
      </div>
      <div className="arrows-wrapper is-hidden-mobile">
        <animated.div
          style={scrollerOpacityProp}
          className="line"
          onClick={() => smoothScrollTo('live-demo')}
        >
          <div className="green-box" />
        </animated.div>
      </div>
    </Section>
  );
};

export default Hero;
