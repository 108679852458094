import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

const SiteHelmet = () => (
  <StaticQuery
    query={graphql`
      query helmetQuery {
        site {
          siteMetadata {
            title
            author
            description
            keywords
            siteUrl
            image
          }
        }
      }
    `}
    render={data => (
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <title>{data.site.siteMetadata.title}</title>
        <html lang="en" />
        <script>var netdataNoBootstrap = true;</script>
        <script
          src="https://london.my-netdata.io/dashboard.js"
          type="text/javascript"
        />
        {/* Google / Search Engine Meta Tags */}
        <meta itemProp="name" content={data.site.siteMetadata.author} />
        <meta
          itemProp="description"
          content={data.site.siteMetadata.description}
        />
        <meta itemProp="image" content={data.site.siteMetadata.image} />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta
          property="og:description"
          content={data.site.siteMetadata.description}
        />
        <meta property="og:image" content={data.site.siteMetadata.image} />
        <meta property="og:image:type" content="image/png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={data.site.siteMetadata.twitterUsername}
        />
        <meta name="twitter:title" content={data.site.siteMetadata.title} />
        <meta
          name="twitter:description"
          content={data.site.siteMetadata.description}
        />
        <meta name="twitter:image" content={data.site.siteMetadata.image} />
      </Helmet>
    )}
  />
);
export default SiteHelmet;
