import React from 'react';
import { animated } from 'react-spring';

import './section.scss';

const Section = ({ children, style, className, fullHeight, name, id }) => {
  const classNames = [className, fullHeight && 'is-fullheight']
    .filter(Boolean)
    .join(' ');

  return (
    <animated.section style={style} className={classNames} name={name} id={id}>
      {!fullHeight && children}
      {fullHeight && <div className="fullheight-container">{children}</div>}
    </animated.section>
  );
};

export default Section;
