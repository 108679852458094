import React, { useState, useRef, useEffect } from 'react';
import { useSpring, config } from 'react-spring';
import { StaticQuery, graphql } from 'gatsby';
import { IoIosSearch } from 'react-icons/io';
import { BsSlashSquare } from 'react-icons/bs';
import { GatsbyImage } from 'gatsby-plugin-image';

import Section from '~components/section';

import './integrations.scss';

function getIntegrationTypes(data, selectedTypeId, setSelectedTypeId) {
  const types = data.allIntegrationTypesJson.edges.map(({ node }) => node);

  return (
    <div>
      <div
        className={`category all ${selectedTypeId === false && 'active'}`}
        key={false}
        onClick={() => setSelectedTypeId(false)}
        role="presentation"
      >
        All
      </div>
      {types.map((type) => {
        const classNames = [
          'category',
          selectedTypeId === type.filterId && 'active',
        ]
          .filter(Boolean)
          .join(' ');

        return (
          <div
            className={classNames}
            key={type.filterId}
            onClick={() => setSelectedTypeId(type.filterId)}
            role="presentation"
          >
            {type.name}
          </div>
        );
      })}
    </div>
  );
}

function getIntegrationResults(data, searchFilter, selectedTypeId, sortByName) {
  const results = data.allIntegrationsJson.edges
    .filter(
      (edge) =>
        !searchFilter ||
        edge.node.title.toLowerCase().includes(searchFilter.toLowerCase())
    )
    .filter(
      (edge) =>
        !selectedTypeId ||
        (edge.node.types && edge.node.types.includes(selectedTypeId))
    )
    .sort(function (a, b) {
      if (sortByName == '1') {
        return a.node.title.localeCompare(b.node.title);
      } else {
        return false;
      }
    })
    .slice(0, 200);

  if (results && results.length) {
    return (
      <div className="results">
        {results.map((edge) => {
          const { node } = edge;
          return (
            <div className="result" key={node.id}>
              <div className="name">{node.title}</div>
              <div className="image">
                {node.logoFilename && (
                  <GatsbyImage
                    image={node.logoFilename.childImageSharp.gatsbyImageData}
                    alt={`${node.name} logo`}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return <div className="no-results">No results found.</div>;
}

const Integrations = ({ atTheBottom }) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedTypeId, setSelectedTypeId] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === '/' && document.activeElement !== inputRef.current) {
        event.preventDefault();
        inputRef.current && inputRef.current.focus();
      } else if (event.key === 'Escape' || event.key === 'Esc') {
        if (document.activeElement === inputRef.current) {
          inputRef.current && inputRef.current.blur();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const props = useSpring({
    transform: atTheBottom ? 'scale3d(0.9, 0.9, 0.9)' : 'scale3d(1, 1, 1)',
    config: config.default,
  });

  return (
    <Section style={props} className="section integrations" id="integrations">
      <div className="container">
        <h1 className="title is-size-2 is-size-3-mobile">Portfolio</h1>
        <p className="subtitle is-size-5-mobile">
          $750M+ AUM across three active funds invested early in 100+ companies
        </p>
        <StaticQuery
          query={graphql`
            query IntegrationsQuery {
              site {
                siteMetadata {
                  integrationsSortByName
                }
              }
              allIntegrationTypesJson {
                edges {
                  node {
                    id
                    filterId
                    name
                    title
                  }
                }
              }
              allIntegrationsJson {
                edges {
                  node {
                    id
                    types
                    title
                    logoFilename {
                      childImageSharp {
                        gatsbyImageData(width: 60, layout: FIXED)
                      }
                    }
                  }
                }
              }
            }
          `}
          render={(data) => {
            return (
              <div className="integrations-table">
                <div className="filtering">
                  {getIntegrationTypes(data, selectedTypeId, setSelectedTypeId)}
                </div>
                <div className="search">
                  <input
                    ref={inputRef}
                    type="text"
                    value={searchFilter}
                    onChange={(e) => {
                      setSearchFilter(e.target.value);
                    }}
                  />
                  <IoIosSearch />
                  {!inputRef.current?.value && (
                    <p className="input-placeholder is-flex is-align-items-center">
                      Type <BsSlashSquare className="slash-icon" />
                      to search
                    </p>
                  )}
                </div>
                {getIntegrationResults(
                  data,
                  searchFilter,
                  selectedTypeId,
                  data.site.siteMetadata.integrationsSortByName
                )}
              </div>
            );
          }}
        />
      </div>
    </Section>
  );
};

export default Integrations;
