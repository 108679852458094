import React, { useEffect, useRef } from 'react';

import TimelineMax from 'gsap/TimelineMax';
import { Back } from 'gsap/TweenMax';
import * as THREE from './three.min.js';
import dotFragmentShader from './dotFragmentshader.glsl';
import elFragmentShader from './elFragmentshader.glsl';
import elVertexShader from './elVertexshader.glsl';
import fragmentShader from './fragmentshader.glsl';
import vertexShader from './vertexshader.glsl';

import './shaders/CopyShader';
import './shaders/BlendShader';

import './postprocessing/EffectComposer';
import './postprocessing/RenderPass';
import './postprocessing/ShaderPass';
import './postprocessing/SavePass';

import './waves.scss';

const Waves = ({ height, size }) => {
  const waveRef = useRef();
  let returningVisit = true;

  useEffect(() => {
    //if (sessionStorage.getItem('returningVisit')) {
    // returningVisit = true;
    // } else {
    //sessionStorage.setItem('returningVisit', true);
    // returningVisit = false;
    //}

    const containerWidth = window.innerWidth;
    const containerHeight = height || window.innerHeight;
    const sizeFactor = size || 2;

    var qualityFactor = 1;

    var SEPARATION = 480 * qualityFactor,
      AMOUNTX = 160 / qualityFactor,
      AMOUNTY = 70 / qualityFactor;

    var camera, uniforms, scene, renderer;

    var particles;

    var data;
    var dotData;
    var dataAmount = 6;

    var mouseX = 0;
    var mouseY = 0;

    var animDataArray = [];
    var dotAnimDataArray = [];
    var postprocessing = {};
    var dataTexture;
    var dotTexture;
    var t0, t1;
    function setup() {
      //Load Textures
      var textureLoader = new THREE.TextureLoader();
      dotTexture = textureLoader.load('images/waves/dot.png', function () {
        dataTexture = textureLoader.load('images/waves/dataempty.png', function () {
          t0 = performance.now();
          init();
          t1 = performance.now();
          console.log("Init took " + (t1 - t0) + " ms.");
          animate();
        });
      });
    }

    var startTime = Date.now();
    var xPos = mouseX;

    setup();

    function init() {
      camera = new THREE.PerspectiveCamera(
        100,
        window.innerWidth / containerHeight,
        1,
        100000
      );
      camera.position.y = 501;
      camera.position.x = -101;
      camera.position.z = 18000;

      scene = new THREE.Scene();

      camera.lookAt(new THREE.Vector3(100.0, 2800.0, 0.0));

      var numParticles = AMOUNTX * AMOUNTY;

      var positions = new Float32Array(numParticles * 3);
      var dataPositions = new Float32Array(dataAmount * 3);
      var indices = new Float32Array(dataAmount);
      var animData = new Float32Array(dataAmount * 3);
      var dotAnimData = new Float32Array(dataAmount * 3);
      var scales = new Float32Array(numParticles);

      var i = 0,
        j = 0,
        k = 0;
      var limX = (AMOUNTX * SEPARATION) / 8;
      var limZ = (AMOUNTY * SEPARATION) / 8;

      for (var ik = 0; ik < dataAmount; ik++, k += 3) {
        var randX = Math.random();
        var randZ = Math.random();
        dataPositions[k] = randX * -limX + (1.0 - randX) * limX;
        dataPositions[k + 1] = 0;
        dataPositions[k + 2] = randZ * -limZ + (1.0 - randZ) * limZ;
        indices[ik] = ik;
        animData[k] = 0.0;
        animData[k + 1] = 0.0;
        animData[k + 2] = 0.0;
        dotAnimData[k] = 0.0;
        dotAnimData[k + 1] = 0.0;
        dotAnimData[k + 2] = 0.0;
      }

      for (var iy = 0; iy < AMOUNTY; iy++) {
        for (var ix = 0; ix < AMOUNTX; ix++) {
          var rand = Math.random();
          positions[i] = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2; // x
          positions[i + 1] = 0; // y
          positions[i + 2] = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2; // z

          scales[j] = rand * 500 * sizeFactor + (1 - rand) * 400 * sizeFactor;

          i += 3;
          j++;
        }
      }

      var geometry = new THREE.BufferGeometry();
      geometry.addAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.addAttribute('scale', new THREE.BufferAttribute(scales, 1));

      var dataGeometry = new THREE.BufferGeometry();
      dataGeometry.addAttribute(
        'position',
        new THREE.BufferAttribute(dataPositions, 3)
      );
      dataGeometry.addAttribute(
        'textureIdAttribute',
        new THREE.BufferAttribute(indices, 1)
      );
      dataGeometry.addAttribute(
        'animData',
        new THREE.BufferAttribute(animData, 3)
      );

      var dotDataGeometry = new THREE.BufferGeometry();
      dotDataGeometry.addAttribute(
        'position',
        new THREE.BufferAttribute(dataPositions, 3)
      );
      dotDataGeometry.addAttribute(
        'textureIdAttribute',
        new THREE.BufferAttribute(indices, 1)
      );
      dotDataGeometry.addAttribute(
        'animData',
        new THREE.BufferAttribute(dotAnimData, 3)
      );

      uniforms = {
        color: { value: new THREE.Color(0xffffff) },
        time: { type: 'f', value: 1.0 },
        frequencies: { value: new THREE.Vector4() },
        speeds: { value: new THREE.Vector4() },
        amplitudes: { value: new THREE.Vector4() },
        dataTexture: { type: 't', value: dataTexture },
        dotTexture: { type: 't', value: dotTexture },
        field: { value: new THREE.Vector2() },
        mouse: { value: new THREE.Vector3() },
      };

      var material = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        depthTest: false,
        transparent: true,
        blending: THREE.AdditiveBlending,
      });

      var dataMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: elVertexShader,
        fragmentShader: elFragmentShader,
        transparent: true,
        depthTest: false,
        blending: THREE.AdditiveBlending,
      });

      var dotMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: elVertexShader,
        fragmentShader: dotFragmentShader,
        transparent: true,
        depthTest: false,
        blending: THREE.NormalBlending,
      });

      uniforms.field.value.x = (AMOUNTX * SEPARATION) / 2.0;
      uniforms.field.value.y = (AMOUNTY * SEPARATION) / 2.0;

      particles = new THREE.Points(geometry, material);
      scene.add(particles);
      data = new THREE.Points(dataGeometry, dataMaterial);
      scene.add(data);
      dotData = new THREE.Points(dotDataGeometry, dotMaterial);
      scene.add(dotData);

      renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(
        window.innerWidth,
        containerHeight + containerHeight * 0.2
      );
      renderer.sortObjects = true;
      waveRef.current.appendChild(renderer.domElement);

      initPostprocessing();

      document.addEventListener('mousemove', onDocumentMouseMove, false);
      document.addEventListener('touchstart', onDocumentTouchStart, false);
      document.addEventListener('touchmove', onDocumentTouchMove, false);

      window.addEventListener('resize', onWindowResize, false);

      var effectController = {
        frequencyA: 6.0,
        frequencyB: 9.0,
        frequencyC: 15.0,
        frequencyD: 5.0, //8.4,
        speedA: 32.0,
        speedB: 37.0,
        speedC: 162.0,
        speedD: 180.0, //201.0
        amplitudeA: 1502.0,
        amplitudeB: 450.0,
        amplitudeC: 313.0, //206.0
        amplitudeD: 200.0, //394.0
      };
      var matChanger = function () {
        uniforms.frequencies.value.x = effectController.frequencyA;
        uniforms.frequencies.value.y = effectController.frequencyB;
        uniforms.frequencies.value.z = effectController.frequencyC;
        uniforms.frequencies.value.w = effectController.frequencyD;

        uniforms.speeds.value.x = effectController.speedA;
        uniforms.speeds.value.y = effectController.speedB;
        uniforms.speeds.value.z = effectController.speedC;
        uniforms.speeds.value.w = effectController.speedD;

        uniforms.amplitudes.value.x = effectController.amplitudeA;
        uniforms.amplitudes.value.y = effectController.amplitudeB;
        uniforms.amplitudes.value.z = effectController.amplitudeC;
        uniforms.amplitudes.value.w = effectController.amplitudeD;
      };

      matChanger();
      setupAnimDataArray();
      animateLoopData();
      onWindowResize();
    }

    function onWindowResize() {
      //width = window.innerWidth;
      //height = containerHeight + containerHeight * 0.2;

      camera.aspect = window.innerWidth / containerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, containerHeight);
      postprocessing.composer.setSize(window.innerWidth, containerHeight);
    }

    function initPostprocessing() {
      var renderPass = new THREE.RenderPass(scene, camera);
      const renderTargetParameters = {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        stencilBuffer: false,
      };

      var savePass = new THREE.SavePass(
        new THREE.WebGLRenderTarget(
          window.innerWidth,
          containerHeight,
          renderTargetParameters
        )
      );
      const blendPass = new THREE.ShaderPass(THREE.BlendShader, 'tDiffuse1');
      blendPass.uniforms['tDiffuse2'].value = savePass.renderTarget.texture;
      blendPass.uniforms['mixRatio'].value = 0.6 / qualityFactor;

      const outputPass = new THREE.ShaderPass(THREE.CopyShader);
      outputPass.renderToScreen = true;

      var composer = new THREE.EffectComposer(renderer);
      composer.addPass(renderPass);
      composer.addPass(blendPass);
      composer.addPass(savePass);
      composer.addPass(outputPass);

      postprocessing.composer = composer;
    }

    //

    function onDocumentMouseMove(event) {
      mouseX = event.clientX / window.innerWidth;
      mouseY = event.clientY / containerHeight;
    }

    function onDocumentTouchStart(event) {
      if (event.touches.length === 1) {
        event.preventDefault();
        mouseX = event.touches[0].pageX / window.innerWidth;
        mouseY = event.touches[0].pageY / containerHeight;
      }
    }

    function onDocumentTouchMove(event) {
      if (event.touches.length === 1) {
        event.preventDefault();
        mouseX = event.touches[0].pageX / window.innerWidth;
        mouseY = event.touches[0].pageY / containerHeight;
      }
    }

    //

    function animate() {
      setInterval(function () {
        //requestAnimationFrame(animate);
        render();
      }, 1000 / (40 / qualityFactor));
    }

    /*--------------------------Animation Region-----------------------------------*/

    function setupAnimDataArray() {
      for (var i = 0; i < dataAmount; i++) {
        animDataArray.push({ opacity: 0.0, scale: 1.0, rotation: 0.0 });
        dotAnimDataArray.push({ opacity: 0.0, scale: 1.0, rotation: 0.0 });
      }
    }

    function updateAllAnimationData() {
      copyAnimData();
      copyDotAnimData();
    }

    function copyAnimData() {
      var animData = data.geometry.attributes.animData.array;
      for (var i = 0, k = 0; i < dataAmount; i++, k += 3) {
        animData[k] = animDataArray[i].opacity;
        animData[k + 1] = animDataArray[i].scale;
        animData[k + 2] = animDataArray[i].rotation;
        data.geometry.attributes.animData.needsUpdate = true;
      }
    }

    function copyDotAnimData() {
      var dotAnimData = dotData.geometry.attributes.animData.array;
      for (var i = 0, k = 0; i < dataAmount; i++, k += 3) {
        dotAnimData[k] = dotAnimDataArray[i].opacity;
        dotAnimData[k + 1] = dotAnimDataArray[i].scale;
        dotAnimData[k + 2] = dotAnimDataArray[i].rotation;
        dotData.geometry.attributes.animData.needsUpdate = true;
      }
    }

    function animateLoopData() {
      for (var i = 0; i < dataAmount; i++) {
        fedeInElement(
          dotAnimDataArray[i],
          animDataArray[i],
          1 + i * 0.15,
          i == 0
        );
      }
    }

    function fedeInElement(
      elementID,
      elementIDText,
      initialDelay,
      addRepeatFC
    ) {
      var onRepeatFC;
      if (addRepeatFC) onRepeatFC = changeDataPosition;

      var tl = new TimelineMax({
        repeat: -1,
        onUpdate: updateAllAnimationData,
        onRepeat: onRepeatFC,
        repeatDelay: 1,
        delay: 0.75 + initialDelay,
      });

      tl.to(elementID, 0.02, { scale: 0, ease: Back.easeOut });
      tl.to(elementIDText, 0.01, { opacity: 0, ease: Back.easeOut });

      tl.to(elementID, 0.35, {
        opacity: 1,
        scale: 1,
        ease: Back.easeOut.config(10),
      });
      tl.to(elementIDText, 0.02, { opacity: 1 });
      tl.to(elementIDText, 0.051, { opacity: 1 });
      tl.to(elementIDText, 0.01, { opacity: 0 });
      tl.to(elementIDText, 0.06, { opacity: 0 });
      tl.to(elementIDText, 0.01, { opacity: 1 });
      tl.to(elementIDText, 0.03, { opacity: 1 });
      tl.to(elementIDText, 0.01, { opacity: 0 });
      tl.to(elementIDText, 0.04, { opacity: 0 });
      tl.to(elementIDText, 0.01, { opacity: 1 });
      tl.to(elementIDText, 0.05, { opacity: 1 });

      /// Take A break

      tl.to(elementIDText, 4, { opacity: 1 });
      tl.to(elementIDText, 0.02, { opacity: 0 });
      tl.to(elementIDText, 0.051, { opacity: 0 });
      tl.to(elementIDText, 0.01, { opacity: 1 });
      tl.to(elementIDText, 0.06, { opacity: 1 });
      tl.to(elementIDText, 0.01, { opacity: 0 });
      tl.to(elementIDText, 0.03, { opacity: 0 });
      tl.to(elementIDText, 0.01, { opacity: 1 });
      tl.to(elementIDText, 0.04, { opacity: 1 });
      tl.to(elementIDText, 0.01, { opacity: 0 });
      tl.to(elementIDText, 0.05, { opacity: 0 });

      tl.to(
        elementID,
        0.351,
        { opacity: 0, scale: 0, ease: Back.easeIn.config(5) },
        '-=0.15'
      );
    }

    function changeDataPosition() {
      var dataPositions = data.geometry.attributes.position.array;
      var dotDataPositions = dotData.geometry.attributes.position.array;
      var k = 0;
      var mousePosX = ((mouseX * 2.0 - 1.0) * AMOUNTX * SEPARATION) / 2.0;
      var limX = (AMOUNTX * SEPARATION) / 32;
      var limZ = (AMOUNTY * SEPARATION) / 4;
      for (var ik = 0; ik < dataAmount; ik++, k += 3) {
        var randX = Math.random();
        var randZ = Math.random();
        dataPositions[k] = mousePosX + randX * -limX + (1.0 - randX) * limX;
        dataPositions[k + 1] = 0;
        dataPositions[k + 2] = randZ * -limZ + (1.0 - randZ) * limZ;

        dotDataPositions[k] = dataPositions[k];
        dotDataPositions[k + 1] = dataPositions[k + 1];
        dotDataPositions[k + 2] = dataPositions[k + 2];
      }
      data.geometry.attributes.position.needsUpdate = true;
      dotData.geometry.attributes.position.needsUpdate = true;
    }

    /*--------------------------Animation Region End-----------------------------------*/

    function render() {
      var elapsedMilliseconds = Date.now() - startTime;
      var elapsedSeconds = elapsedMilliseconds / 1000;
      if (returningVisit) {
        uniforms.time.value = 60 * elapsedSeconds + 500;
      } else {
        uniforms.time.value = 60 * elapsedSeconds;
      }

      // uniforms.time.value = 60 * elapsedSeconds;
      //camera.position.y = Math.sin(elapsedSeconds * 1) * 500.0;

      xPos += (mouseX - xPos) / 60.0;
      uniforms.mouse.value.x = xPos;
      uniforms.mouse.value.y = mouseY;
      postprocessing.composer.render(0.1);
      // if you comment out postprocessing use :renderer.render(scene, camera);
    }
  }, []);

  return <div ref={waveRef} className="waves" />;
};

export default Waves;