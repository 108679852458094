import { scroller } from 'react-scroll';

const smoothScrollTo = (elementName, opts) => {
  let options = opts;
  if (!opts) {
    options = {
      smooth: true,
      duration: 500,
    };
  }
  scroller.scrollTo(elementName, options);
};

const getNumberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export { smoothScrollTo, getNumberWithCommas };
