/**
 * @author alteredq / http://alteredqualia.com/
 */

import * as THREE from '../three.min';

THREE.SavePass = function(renderTarget) {
  THREE.Pass.call(this);

  if (THREE.CopyShader === undefined)
    console.error('THREE.SavePass relies on THREE.CopyShader');

  var shader = THREE.CopyShader;

  this.textureID = 'tDiffuse';

  this.uniforms = THREE.UniformsUtils.clone(shader.uniforms);

  this.material = new THREE.ShaderMaterial({
    uniforms: this.uniforms,
    vertexShader: shader.vertexShader,
    fragmentShader: shader.fragmentShader,
  });

  this.renderTarget = renderTarget;

  if (this.renderTarget === undefined) {
    this.renderTarget = new THREE.WebGLRenderTarget(
      window.innerWidth,
      window.innerHeight,
      {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBFormat,
        stencilBuffer: false,
      }
    );
    this.renderTarget.texture.name = 'SavePass.rt';
  }

  this.needsSwap = false;

  this.fsQuad = new THREE.Pass.FullScreenQuad(this.material);
};

THREE.SavePass.prototype = Object.assign(Object.create(THREE.Pass.prototype), {
  constructor: THREE.SavePass,

  render: function(renderer, writeBuffer, readBuffer) {
    if (this.uniforms[this.textureID]) {
      this.uniforms[this.textureID].value = readBuffer.texture;
    }

    renderer.setRenderTarget(this.renderTarget);
    if (this.clear) renderer.clear();
    this.fsQuad.render(renderer);
  },
});
